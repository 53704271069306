



































































import { getSkuIdList } from '@/api/product';
import { IBaseShoppingCartItem, IBaseStand, PayTypeEnum, ProductTypeEnum } from '@/api/type/base';
import { GetPromiseReturn } from '@/api/type/tool';
import { AddressItem } from '@/api/user/address';
import { Vue, Component, Provide } from 'vue-property-decorator';
import ConfirmStandCard from './comm/ConfirmStandCard.vue';
import ConfirmCoupon from './comm/ConfirmCoupon.vue';
import ConfirmAddress from './comm/ConfirmAddress.vue';
import ConfirmAccount from './comm/ConfirmAccount.vue';
import ConfirmRemarks from './comm/ConfirmRemarks.vue';
import { calcMoney, formatMoney } from '@/filters/calcMoney';
import { getCouponUsableList } from '@/api/user/coupon';
import { Toast } from 'vant';
import { createOrder, getWechatPrepay } from '@/api/pay';
import wxApi from '@/lib/jsskd';
import router from '@/router';
import {sortBy} from "lodash";
type ISkuList = GetPromiseReturn<typeof getSkuIdList>['payload'];
type ICouponData = GetPromiseReturn<typeof getCouponUsableList>['payload'][number];
type GCreateOrderInfo = Pick<IBaseShoppingCartItem, 'skuId' | 'amount'>;
@Component({
  name: 'BaseConfirmOrder',
  components: { ConfirmAddress, ConfirmStandCard, ConfirmCoupon, ConfirmAccount, ConfirmRemarks }
})
export default class extends Vue {
  checked = '';
  /* 地址Id */
  addressId: AddressItem['addressId'] = '';
  /* 充值账号信息 */
  rechargeAccount = '';
  /* 规格详情 */
  skuList: ISkuList = [];
  /* 商品类型 */
  productType?: ProductTypeEnum = undefined;
  /* 折扣信息 */
  couponInfo = {} as ICouponData;
  /* 是否显示折扣券 */
  isCoupon = false;
  /* 支付方式 默认微信支付 */
  payType: string = PayTypeEnum.POINT;
  /* 订单备注 */
  orderRemarks = '';
  /* 订单备注弹窗是否显示 */
  isOrderRemarksInputs = false;

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  /* 创建订单传递过来的参数 */
  @Provide()
  get createOrderInfo(): GCreateOrderInfo[] {
    const cc = JSON.parse(this.$route.query.skuInfo as any) as GCreateOrderInfo[];
    cc.sort((a,b) => a.skuId -b.skuId)
    return cc;
  }

  /* 总金额 */
  get totalPrice() {
    return formatMoney(
      this.skuList.reduce((price, item, index) => {
        return price + item.currentPrice * this.createOrderInfo[index].amount;
      }, 0)
    );
  }

  get payTotalPrice() {
    return formatMoney(Number(this.totalPrice) - calcMoney(this.couponInfo.consumePrice), false);
  }

  onSelectCoupon(item: ICouponData) {
    this.couponInfo = item;
  }
  async pay() {
    this.beforePay();
    if (this.productType === this.productTypeEnum.PRODUCT_TYPE_PHYSICAL && !this.addressId) {
      Toast('未添加收货地址');
      return;
    }

    const {
      payload: { prepayId }
    } = await createOrder({
      skuList: this.createOrderInfo
        .map(item => ({
          skuId: item.skuId,
          num: item.amount
        }))
        .sort((a, b) => a.skuId - b.skuId),
      payType: this.payType,
      addressId: this.addressId,
      rechargeAccount: this.rechargeAccount,
      orderRemark: this.orderRemarks,
      detailPayType: this.payType === 'wechat' ? 'h5' : '',
      orderCoupon: {
        couponNumber: this.couponInfo.couponNumber,
        couponPassword: this.couponInfo.couponPassword
      }
    });
    if (prepayId) {
      const { payload: payConfig } = await getWechatPrepay(prepayId);
      try {
        await wxApi.onPay(payConfig);
        this.afterPay();
      } catch {
        router.replace({ name: 'OrderList' });
      }
    } else {
      router.replace({ name: 'OrderList' });
    }
  }

  async getSkuIdList(skuIds: IBaseStand['skuId'][]) {
    const { payload } = await getSkuIdList(skuIds);
    this.skuList = payload;
  }

  async created() {
    this.productType = this.$route.query.productType as ProductTypeEnum;
    await this.getSkuIdList(this.createOrderInfo.map(item => item.skuId));
  }

  /* 支付前 */
  protected beforePay() {
    //
  }
  /* 实际支付金额 */
  protected afterPay() {
    router.replace({
      name: 'buyRes',
      query: {
        payType: this.payType,
        price: this.totalPrice
      }
    });
  }
}
